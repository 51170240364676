<template>
  <b-overlay
    :show="loading"
    style="min-height: 20vw; width: 100%"
    opacity="0.5"
  >
    <div class="dahsboard-reports">
      <div
        v-if="generatingError || generating || generated"
        class="feedback-row "
      >
        <div
          v-if="generatingError"
          class="error-danger"
        >
          Ocorreu um erro ao gerar um novo relatório, por favor tente novamente mais tarde!
        </div>
        <div
          v-if="generating"
          class="error-success"
        >
          O seu relatório está a ser gerado.
        </div>
        <div
          v-if="generated"
          class="error-success"
        >
          O seu relatório foi gerado com sucesso.
        </div>
      </div>
      <div
        v-for="(report, k) in reports"
        :key="k"
        class="report dashboard-reports"
      >
        <div class="report-header">
          <b>
            Relatório #{{ reports.length - k }}
          </b>
          <div style=" align-items: center; display: flex; flex-direction: row; gap: 8px;">
            <b>Avaliação global (ponderada): <span class="text-primary ml-2 mr-2">{{ parseFloat(report.global[0].classification).toFixed(2) }} - Nível {{ Math.floor(report.global[0].classification) }}</span></b>
            <div
              v-if="report.quiz[0] && report.quiz[0].pdf"
              class="button primary mr-2 sm"
              @click="() => regeneratePdf(report.quiz[0].id)"
            >
              <b-icon
                scale="1"
                icon="arrow-clockwise"
                class="mr-3"
              />Gerar Novo PDF
            </div>
            <div
              v-if="report.quiz[0] && report.quiz[0].pdf"
              class="button primary mr-2 sm"
              @click="download(report.quiz[0].pdf)"
            >
              <b-icon
                scale="1"
                icon="download"
                class="mr-3"
              />Download
            </div>
            <b-icon
              icon="chevron-down"
              class="icon"
              @click="toggle(k)"
            />
          </div>
        </div>
        <div v-if="open === k">
          <div class="mb-2">
            Data: {{ report.global[0].updated_at }}
          </div>
          <div
            v-if="report.global[0] && report.dimensions"
            class="report-dimensions-classification"
          >
            <div class="title">
              Nas 6 dimensões analisadas, os níves de maturidade i4.0 são os seguintes:
            </div>
            <div class="row-dimensions-classification">
              <div class="dimensions">
                <div
                  class="row-dimensions dimensions-header"
                >
                  <div class="dimension">
                    Dimensão
                  </div>
                  <div class="classification">
                    Nível Atual
                  </div>
                  <div class="classification">
                    Nível Futuro
                  </div>
                </div>
                <div
                  v-for="(dim, j) in Object.keys(report.dimensions)"
                  :key="j"
                  class="row-dimensions"
                >
                  <div class="dimension">
                    {{ report.dimensions[dim].name }}
                  </div>
                  <div class="classification">
                    {{ report.dimensions[j].classification }}
                  </div>
                  <div class="classification">
                    {{ report.future[j].value }}
                  </div>
                </div>
                <div
                  class="row-dimensions"
                >
                  <div class="dimension">
                    Avaliação
                  </div>
                  <div
                    class="classification bg-primary text-light"
                    style="border-top-left-radius: 5px; border-bottom-left-radius: 5px"
                  >
                    {{ report && report.global ? Math.floor(report.global[0].classification) : null }}
                  </div>
                  <div
                    class="classification bg-primary text-light"
                    style="border-top-right-radius: 5px; border-bottom-right-radius: 5px"
                  >
                    {{ Math.floor(report.future_classification) }}
                  </div>
                </div>
              </div>

              <div
                class="classifications"
              >
                <img
                  :src="require(`@/assets/N${Math.floor(report.global[0].classification)}.svg`)"
                >
              </div>
            </div>
          </div>
          <div
            v-if="report.quiz[0].svg && report.dimensions"
            class="report-themes-classification"
          >
            <div class="title">
              Na avaliação dos temas, de cada dimensão, obteve as seguintes classificações:
            </div>
            <div class="row-themes-classification">
              <div
                class="svg"
                v-html="report.quiz[0].svg"
              />

              <div class="table">
                <div class="row-dimensions">
                  <div class="dimension">
                    Dimensão
                  </div>
                  <div class="themes">
                    <div class="row-themes">
                      <div
                        class="theme"
                      >
                        Tema
                      </div>
                      <div
                        class="classification"
                      >
                        Nível
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-for="(dim, j) in Object.keys(report.dimensions)"
                  :key="`dimension-${j}`"
                  class="row-dimensions"
                >
                  <div
                    class="dimension"
                  >
                    {{ report.dimensions[dim].name }}
                  </div>
                  <div
                    class="themes"
                  >
                    <div
                      v-for="(theme, i) in Object.keys(report.themes.filter(e => e.dimension_id == report.dimensions[dim].id))"
                      :key="`theme-${i}`"
                      class="row-themes"
                    >
                      <div
                        class="theme"
                      >
                        {{ report.themes.filter(e => e.dimension_id == report.dimensions[dim].id)[theme].name }}
                      </div>
                      <div
                        class="classification"
                      >
                        {{ report.themes[report.themes.indexOf(report.themes
                          .filter(e => e.dimension_id == report.dimensions[dim].id)[theme])].classification }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="reports && reports.length === 0"
        class="report dashboard-reports"
      >
        <h5>Sem relatórios disponíveis</h5>
        <h6 class="mt-4">
          Após a submissão de um questionário, será possível visualizar o relatório de maturidade i4.0 da sua empresa.
        </h6>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { get, put } from '../../services/api';
import { generateChartSite } from '../../services/common/generate-chart-site';

export default {
  name: 'DashboardReports',
  data() {
    return {
      quiz: null,
      loading: true,
      id: null,
      global: null,
      svgResult: null,
      dimensions: null,
      themes: null,
      pdf: null,
      quizzes: null,
      reports: null,
      open: -1,
      generated: false,
      generating: false,
      generatingError: false,
    };
  },

  async created() {
    const id = this.$store.getters.reportQuiz;
    await get('quiz', {})
      .then(async (response) => {
        if (response.data) {
          this.quizzes = response.data;
          this.reports = [];
          await this.asyncForEach(this.quizzes, async (quiz) => {
            if (quiz.quiz.status !== 'opened' && quiz.quiz.status !== 'open') {
              await get(`score/${quiz.quiz.id}`, {})
                .then((response) => {
                  this.reports.push(response.data);
                });
            }
          });
        }
      });
    if (id) {
      this.open = this.reports.indexOf(this.reports.filter((el) => el.global[0].quiz_id === id)[0]);
    } else {
      this.open = 0;
    }

    this.loading = false;
  },
  methods: {
    async regeneratePdf(id) {
      this.generated = false;
      this.generating = false;
      this.generatingError = false;
      await get(`score/${id}`, {})
        .then(async (response) => {
          const report = response.data;
          const svg = generateChartSite(
            report.themes.map((el) => el.classification),
            report.dimensions.map((el) => el.classification),
            report.global,
            report.future,
            report.kos,
          ).innerHTML.replaceAll('textpath', 'textPath').replaceAll('startoffset', 'startOffset');

          await put(`/quiz/${id}`, {
            svg,
          }).then(() => {
            get(`/score/pdf/${id}`, {}).then(() => {
              this.generated = true;
              this.generating = false;
            }).catch(() => {
              this.generated = false;
              this.generating = false;
              this.generatingError = true;
            });
            this.generating = true;
          }).catch(() => {
            this.generatingError = true;
          });
        }).catch((e) => {
          this.generatingError = true;
        });
    },
    download(pdf) {
      const linkSource = `data:application/pdf;base64,${pdf}`;
      const downloadLink = document.createElement('a');
      const fileName = 'RelatórioI4.0.pdf';
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    toggle(value) {
      if (this.open !== value) {
        this.open = value;
      } else {
        this.open = -1;
      }
    },
    async asyncForEach(array, callback) {
      /* eslint-disable no-await-in-loop */
      for (let index = 0; index < array.length; index += 1) {
        await callback(array[index], index, array);
      }
      /* eslint-enable no-await-in-loop */
    },
  },
};
</script>
